<template>
    <article class="intima overflow-hidden">
        <intima-home></intima-home>
        <intima-causes></intima-causes>
        <intima-feature1></intima-feature1>
        <intima-feature2></intima-feature2>
        <intima-feedback></intima-feedback>
        <intima-comparison></intima-comparison>
        <intima-faq></intima-faq>
        <enquiry duplicate-to-g-sheet
                 show-deposit-discount-notice
                 g-sheet-id-key="branch"
                 currency="hkd"
                 amount="3400"
                 v-bind:g-sheet-id="gSheetIds"
                 v-bind:treatment-id="treatmentId">
            免費專業美容顧問咨詢 及 安排首次半價體驗
            <template v-slot:discount>
                {{ currentMonth }}月份尊享訂金折扣優惠，客戶於登記時同時以PayMe繳付訂金，即可獲得與訂金同等價值之折扣。
                例子: 您為Ultra FEMME 360療程支付了$500訂金，我們將會在應收的體驗價格 – 即$4,500，提供$500扣減優惠，即是次體驗價為$4,000。
                療程當日亦只需在按金外繳付單價$3,500。每次登記最高可享優惠$500<sup>2</sup>。
            </template>
        </enquiry>
        <btn-register-now></btn-register-now>
    </article>
</template>

<script>
import Enquiry from "./Enquiry";
import IntimaFaq from "./Intima/Faq";
import IntimaComparison from "./Intima/Comparison";
import IntimaFeedback from "./Intima/Feedback";
import IntimaHome from "./Intima/Home";
import IntimaCauses from "./Intima/Causes";
import IntimaFeature1 from "./Intima/Feature1";
import IntimaFeature2 from "./Intima/Feature2";
import BtnRegisterNow from "./Intima/BtnRegisterNow";

const monthMap = [
    "一", "二", "三", "四", "五", "六", "七", "八", "九", "十", "十一", "十二",
];

export default {
    name: "Intima",
    components: {
        BtnRegisterNow,
        IntimaFeature2,
        IntimaFeature1,
        IntimaCauses,
        IntimaHome,
        IntimaFeedback,
        IntimaComparison,
        IntimaFaq,
        Enquiry,
    },
    metaInfo () {
        return {
            title: this.$sanitize(this.treatment.title[this.$i18n.locale]),
            meta: [{
                "name": "description",
                "content": this.$sanitize(this.treatment.subtitle[this.$i18n.locale]),
                "vmid": "description",
            }, {
                "property": "og:title",
                "content": this.$sanitize(this.treatment.title[this.$i18n.locale]),
                "template": "%s | marier Skincare 曼肌兒",
                "vmid": "og:title"
            }, {
                "property": "og:description",
                "content": this.$sanitize(this.treatment.subtitle[this.$i18n.locale]),
                "vmid": "og:description"
            }, {
                "property": "og:type",
                "content": "profile",
                "vmid": "og:type"
            }, {
                "property": "fb:app_id",
                "content": "148635335285458",
                "vmid": "fb:app_id"
            }, /*{
                    "property": "og:image",
                    "content": `${ this.$config.apiUrl }/${ this.$config.apiVersion }/shop/product/image/${ this.product.images[0] }`,
                    "vmid": "og:image"
                }*/]
        }
    },
    props: {
        "treatmentId": {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            "gSheetIds": {
                "尖沙咀彌敦道132號美麗華廣場A座6樓602室": "AKfycby93s7mJnKmzHMVXiFV61PW7IbdNsPZeBjdRypSQE8cybctymo8", // TST
                "上門療程服務": "AKfycby93s7mJnKmzHMVXiFV61PW7IbdNsPZeBjdRypSQE8cybctymo8"
            },
            "currentMonth": monthMap[new Date().getMonth()],
        };
    },
    computed: {
        treatment () {
            let target = this.$store.state.mall.salon.treatments.find(
                treatment => treatment._id === this.treatmentId
            );
            if (target) {
                return target;
            }
            return {
                title: {
                    "en": "",
                    "zh-hk": "",
                    "zh-cn": ""
                },
                subtitle: {
                    "en": "",
                    "zh-hk": "",
                    "zh-cn": ""
                },
                availableBranches: [],
                images: [],
                cover: "",
            };
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
